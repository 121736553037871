.text-shadow {
    text-shadow: 1px 1px 2px black, -1px -1px 2px black, 1px -1px 2px black, -1px 1px 2px black;
}

/* bottom right container-publications */
.bottom-left {
    position: absolute;
    bottom: -5rem;
    left: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.bottom-left img {
    width: 12.5%;
    padding: 0.5rem;
    margin-bottom: -1rem;
}

.bottom-left img.gemmy-main {
    width: 14%;
}

.bottom-left img.oak-equity {
    width: 10%;
}

.oak-equity {
    margin-top: 1.2rem;
    width: 6.5rem;
    height: 6.5rem;
}

.smaller-container {
    position: absolute;
    bottom: -3.5rem;
    right: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    width: 55%;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 0.3rem;
    color: white;
    text-align: center;
    font-size: 0.8rem;
}


.features-content {
    display: flex;
    justify-content: space-between;
}

.features-title {
    font-weight: bold;
}

.feature-item {
    flex: 1;
    padding: 0.1rem;
}

.underline {
    text-decoration: underline;
}

.underline:hover {
    color: gold;
}

.award-text {
    font-size: 1.5rem;
    line-height: 2;
    padding-top: 3rem;
}

.responsive-text {
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: 'Poppins';
}


.custom-emily {
    width: 24rem;
    margin-left: -1rem;

}



@media (max-width: 1222px) {

    .smaller-container {
        font-size: 0.7rem;
    }

    .bottom-left img.oak-equity {
        width: 10%;
    }
}


@media (max-width: 1212px) {
    .responsive-text p {
        font-size: .9rem;
        line-height: 1.5rem;
    }

    .smaller-container {
        bottom: -4.3rem;
    }
}


@media (max-width: 1112px) {
    .responsive-text p {
        font-size: .83rem;
        line-height: 1.5rem;
    }

    .bottom-left img.oak-equity {
        width: 9%;
    }
}

@media (max-width: 1069px) {
    .smaller-container {
        font-size: .65rem;
    }

    .bottom-left img.oak-equity {
        width: 8%;
    }
}


@media (max-width: 1060px) {
    .bottom-left img.oak-equity {
        width: 0;
    }

    .smaller-container {
        right: 5rem;
    }
}


@media (max-width: 1020px) {

    .bottom-left img.oak-equity {
        width: 0;
    }
}

@media (max-width: 1006px) {
    .responsive-text p {
        font-size: .83rem;
        line-height: 1.4rem;
    }


}

@media (max-width: 992px) {
    .responsive-text p {
        font-size: .78rem;
        line-height: 1.4rem;
    }
}

@media (max-width: 966px) {
    .responsive-text p {
        font-size: .74rem;
        line-height: 1.4rem;
    }
}

@media (max-width: 934px) {
    .smaller-container {
        font-size: .57rem;
    }
}

@media (max-width: 922px) {
    .responsive-text p {
        font-size: .7rem;
        line-height: 1.4rem;
    }
}

@media (max-width: 904px) {
    .responsive-text p {
        font-size: .7rem;
        line-height: 1.4rem;
    }

    .smaller-container {
        font-size: 0.57rem;
    }
}


@media (max-width: 875px) {
    .responsive-text p {
        font-size: .69rem;
        line-height: 1.3rem;
    }
}

@media (max-width: 846px) {
    .responsive-text p {
        font-size: .66rem;
        line-height: 1.3rem;
    }
}

@media (max-width: 831px) {
    .responsive-text p {
        font-size: .65rem;
        line-height: 1.3rem;
    }

    .smaller-container {
        font-size: 0.5rem;
    }
}




@media (max-width: 785px) {
    .responsive-text p {
        font-size: .65rem;
        line-height: 1.3rem;
    }

}

@media (max-width: 769px) {
    .custom-emily {
        width: 20rem;
    }

    .responsive-text p {
        font-size: .65rem;
        line-height: 1.3rem;
    }

}


@media (max-width: 751px) {
    .smaller-container {
        font-size: .46rem;
    }
}

@media (max-width: 717px) {
    .responsive-text p {
        font-size: .65rem;
        line-height: 1.2rem;
    }
}

@media (max-width: 691px) {
    .responsive-text p {
        font-size: .65rem;
        line-height: 1.2rem;
    }

    .smaller-container {
        font-size: .4rem
    }
}

@media (max-width: 683px) {
    .responsive-text p {
        font-size: .64rem;
        line-height: 1rem;
    }

    .smaller-container {
        right: 3rem;
        bottom: -4.2rem;
    }

}

@media (max-width: 648px) {
    .responsive-text p {
        font-size: .6rem;
        line-height: 1rem;
    }
}


@media (max-width: 622px) {
    .responsive-text p {
        font-size: .6rem;
        line-height: .9rem;
    }
}

@media (max-width: 596px) {
    .award-text {
        font-size: 1.3rem;
        padding-top: 5rem;
    }
}

@media (max-width: 579px) {
    .responsive-text p {
        font-size: .6rem;
        line-height: .8rem;
    }
}


@media (max-width: 565px) {
    .smaller-container {
        font-size: .35rem;
        bottom: -4.2rem;
        right: 2rem;
    }
}

@media (max-width: 533px) {
    .smaller-container {
        font-size: .32rem;
        bottom: -4.2rem;
        right: 2rem;
    }

    .award-text {
        font-size: 1.1rem;
        padding-top: 8rem;
    }
}

@media (max-width: 493px) {
    .smaller-container {
        font-size: .25rem;
        bottom: -4.2rem;
        right: 2.5rem;

    }

    .responsive-text p {
        font-size: .5rem;
        line-height: .75rem;
    }
}

@media (max-width: 457px) {
    .responsive-text p {
        font-size: .5rem;
        line-height: .75rem;
        bottom: -4.2rem;
        right: 2rem;
    }

    .smaller-container {
        right: .5rem;
    }
}