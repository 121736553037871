/* GemmyRoll.css */
.full-slide {
    display: flex;
    height: 100vh;
    width: 100vw;
    margin: -2rem;
    padding: 0;
    position: relative;
    overflow: hidden;
}

.background-half {
    height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
}

.left-background {
    left: 0;
    background-color: #a7f3d0;
    /* Equivalent to bg-emerald-200 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    /* Add padding to the content */
}

.right-background {
    right: 0;
    background-color: #fef3c7;
    /* Equivalent to bg-yellow-200 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    /* Add padding to the content */
}

.center-content2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* Align items to the top */
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    margin-top: 10rem;
    /* Move the entire content down */
}

.right-image {
    max-width: 40%;
    margin-bottom: -8.5rem;
}

.right-header2 {
    font-size: 1.5rem;
    margin-bottom: 14rem;
    color: #333;
    width: 20rem;
    font-family: 'Josefin Sans', sans-serif;
    text-align: center;
}



.syringe-image2 {
    max-width: 32%;
    max-height: 32%;
    position: absolute;
    margin-top: .4rem;
    /* Adjust to move the syringe behind the gemFrGrn */
    z-index: 1;
}

.center-image2 {
    /* margin-top: 12.3rem; */
    z-index: 2;
}


.left-header2 {
    font-size: 1.5rem;
    /* margin-top: 4rem; */
    margin-bottom: 14rem;
    color: #333;
    width: 20rem;
    font-style: bold;
    font-family: 'Josefin Sans', sans-serif;
    text-align: center;
}

.left-background2 {
    left: 0;
    background-color: #fef3c7;
    /* Equivalent to bg-emerald-200 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    /* Add padding to the content */
}

.right-background2 {
    right: 0;
    background-color: #faa957;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

@media (max-width: 1370px) {
    .syringe-image2 {
        max-width: 31%;
        max-height: 31%;
        top: .4rem;
    }

    .center-image2 {
        margin-top: 12.4rem;
    }
}


/* 
@media (max-width: 1262px) {
    .syringe-image2 {
        max-width: 27%;
        max-height: 27%;
        top: 2.3rem;
    }
} */


@media (max-width: 1120px) {
    .syringe-image2 {
        max-width: 27%;
        max-height: 27%;
        top: 2rem;
    }
}

@media (max-width: 915px) {
    .syringe-image2 {
        max-width: 24%;
        max-height: 24%;
        top: 3.2rem;
    }

    .right-header2 {
        font-size: 1.2rem;
        margin-bottom: 12rem;
    }

    .left-header2 {
        font-size: 1.2rem;
        margin-bottom: 12rem;
    }
}

@media (max-width: 849px) {
    .syringe-image2 {
        max-width: 22%;
        max-height: 22%;
        top: 4rem;
    }
}

@media (max-width: 766px) {
    .syringe-image2 {
        max-width: 18%;
        max-height: 18%;
        top: 5.5rem;
    }

    .right-header2 {
        font-size: .8rem;
        margin-bottom: 6rem;
    }

    .left-header2 {
        font-size: .8rem;
        margin-bottom: 6rem;
    }
}


@media (max-width: 625px) {
    .syringe-image2 {
        max-width: 15%;
        max-height: 15%;
        top: 6.7rem;
    }
}


@media (max-width: 500px) {
    .syringe-image2 {
        max-width: 13%;
        max-height: 13%;
        top: 7.7rem;
    }

    .right-header2 {
        margin-bottom: 10rem;
    }

    .left-header2 {
        margin-bottom: 10rem;
    }

    .center-image2 {
        margin-top: 12.5rem;
    }
}


@media (max-width: 480px) {

    .syringe-image2 {

        top: 7.8rem;
    }

    .right-header2 {
        font-size: .6rem;
    }

    .left-header2 {
        font-size: .6rem;
    }
}