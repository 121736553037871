.text-shadow {
    text-shadow: 1px 1px 2px black, -1px -1px 2px black, 1px -1px 2px black, -1px 1px 2px black;
}


img.overlay {
    position: absolute;
    width: 30%;
    height: auto;
    top: 44%;
    left: 75%;
    transform: translate(-50%, -50%);
}

img.overlay2 {
    position: absolute;
    width: 32%;
    height: auto;
    top: 46%;
    left: 28%;
    transform: translate(-50%, -50%);
}

.text1,
.text2 {
    position: absolute;
    text-align: center;
    color: black;
    font-weight: bold;
    font-size: 2rem;
    font-family: 'Josefin Sans', sans-serif;
    background: rgba(255, 255, 255, 0.5);
    /* 50% transparent white */
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.text1 {
    width: 30%;
    top: 80%;
    left: 75%;
    transform: translate(-50%, -50%);
    margin-top: 4.5rem;

}

.text2 {
    width: 30%;
    top: 80%;
    left: 28%;
    transform: translate(-50%, -50%);
    margin-top: 4.5rem;
}

.gemmy-container {
    height: 100%;
    width: 100%;
}



.responsive-container3 {
    position: relative;
}

.image-container {
    position: relative;
    z-index: 1;
}

.responsive-image3 {
    z-index: 1;
    max-width: 85%;
    max-height: 85%;
    width: auto;
    height: auto;
}

.form-container {
    position: relative;
    z-index: 2;
}

.form-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
}

body,
html,
#root {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

@media (max-width: 887px) {
    .text1 {
        top: calc(80% - 4rem);
        font-size: 1.6rem;
    }

    .text2 {
        top: calc(80% - 4rem);
        font-size: 1.6rem;
    }

    .form-container {
        height: auto;
        position: relative;
        padding: .5rem 0.3rem;
    }

    .form-content {
        position: relative;
        top: auto;
        transform: none;
        padding: 0.3rem;
        border-radius: 8px;
    }

    .responsive-image3 {
        width: 50%;
        margin-left: 2%;
    }

    .responsive-container3 {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 840px) {
    .responsive-image3 {
        width: 55%;
    }
}

@media (max-width: 768px) {
    .responsive-image3 {
        width: 60%;
    }

    img.overlay {
        top: calc(44% - 4rem);
    }

    img.overlay2 {
        top: calc(44% - 4rem);
    }

    .text1 {
        top: calc(80% - 4rem);
        font-size: 1.4rem;
        /* Adjust the font size as needed */
        padding: -.5rem;
        margin-top: .5rem;
    }

    .text2 {
        top: calc(80% - 4rem);
        font-size: 1.4rem;
        /* Adjust the font size as needed */
        padding: -.5rem;
        margin-top: .5rem;
    }
}

@media (max-width: 681px) {
    .responsive-image3 {
        width: 60%;
        margin-left: 5%;
    }

    img.overlay {
        top: calc(50% - 4rem);
    }

    img.overlay2 {
        top: calc(50% - 4rem);
    }

    .text1 {
        top: calc(80% - 4rem);
        font-size: 1.2rem;
    }

    .text2 {
        top: calc(80% - 4rem);
        font-size: 1.2rem;
    }
}

@media (max-width: 607px) {
    .responsive-image3 {
        width: 70%;
        margin-left: 5%;

    }

    img.overlay {
        top: calc(40% - 4rem);
    }

    img.overlay2 {
        top: calc(40% - 4rem);
    }

    .text1 {
        top: calc(80% - 4rem);
        font-size: 1.1rem;
        margin-top: -4rem;
    }

    .text2 {
        top: calc(80% - 4rem);
        font-size: 1.1rem;
        margin-top: -4rem;

    }

}

@media (max-width: 549px) {

    img.overlay {
        top: calc(40% - 4rem);
    }

    img.overlay2 {
        top: calc(40% - 4rem);
    }

    .text1 {
        top: calc(80% - 4rem);
        font-size: .9rem;
        margin-top: -6rem;
    }

    .text2 {
        top: calc(80% - 4rem);
        font-size: .9rem;
        margin-top: -6rem;

    }

    .form-container {
        height: auto;
        position: relative;
        padding: 1rem 0.5rem;
    }

    .form-content {
        position: relative;
        top: auto;
        transform: none;
        padding: 0.5rem;
        border-radius: 8px;
    }

    .responsive-image3 {
        width: 80%;
        margin-left: 7%;
    }

    .responsive-container3 {
        flex-direction: column;
        align-items: center;
    }

}

@media (max-width: 491px) {
    img.overlay {
        top: calc(40% - 4rem);
    }

    img.overlay2 {
        top: calc(40% - 4rem);
    }

    .text1 {
        top: calc(80% - 4rem);
        font-size: .7rem;
        margin-top: -8rem;
    }

    .text2 {
        top: calc(80% - 4rem);
        font-size: .7rem;
        margin-top: -8rem;

    }

}