.headroom-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4b5a70;
}

.headroom {
    top: 0;
    left: 0;
    right: 0;
    z-index: 49;
    transition: background-color 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.headroom--unfixed,
.headroom--unpinned,
.headroom--pinned {
    position: fixed;
    width: 100%;
    background-color: #4b5a70;
}

.headroom--unpinned {
    transform: translateY(0);
}

.headroom--pinned {
    transform: translateY(0);
}

.headroom--scrolled {
    transition: background-color 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    width: 100%;
    height: 5rem;
    background-color: rgba(75, 90, 112, 0.5);
    /* Half opacity */
    transition: background-color 0.5s ease-in-out;
}

.nav-left {
    display: flex;
    align-items: center;
    padding-left: 1rem;
}

.nav-brand {
    font-size: 1.6rem;
    font-weight: bold;
    color: white;
    font-family: 'Josefin Sans', sans-serif;
    text-shadow: 1px 1px 2px black;
    text-align: left;
    display: flex;
    font-style: italic;
    margin-left: 6rem;
}

.nav-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5rem;
    margin-right: 2rem;
}

.nav-link {
    font-size: 1.5rem;
    color: white;
    font-family: 'Josefin Sans', sans-serif;
    text-shadow: 1px 1px 2px black;
    margin-left: 1.5rem;
    text-decoration: none;
    font-style: italic;
}

.nav-hamburger {
    display: none;
    padding-right: 4rem;
}

.hamburger-icon {
    cursor: pointer;
    width: auto;
    height: 3.5rem;
}

.nav-menu {
    position: fixed;
    top: 5rem;
    right: 0;
    width: 100%;
    background-color: rgba(75, 90, 112, 0.7);
    z-index: 50;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
}

.nav-menu-link {
    font-size: 1.5rem;
    color: white;
    font-family: 'Josefin Sans', sans-serif;
    text-shadow: 1px 1px 2px black;
    margin: 0.5rem 0;
    text-decoration: none;
}

@media (max-width: 750px) {
    .nav-brand {
        margin-left: 3rem;
    }


    .nav-links {
        display: none;
    }

    .nav-hamburger {
        display: flex;
    }
}

@media (max-width: 500px) {
    .nav-brand {
        flex-direction: column;
        /* Stack words on top of each other */
    }
}