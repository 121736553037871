.footer {
    padding: 2.5rem 0;
    /* Increased padding to make the footer taller */
    text-align: center;
    color: white;
    font-family: 'Josefin Sans', sans-serif;
}

.footer-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.25rem;
}

.footer-icon {
    width: 3.75rem;
    /* 60px */
    height: 3.75rem;
    /* 60px */
    margin: 0 1.25rem;
    /* 20px */
    object-fit: contain;
    /* Ensure the images are contained within the specified dimensions */
}

.footer-icon-empress {
    width: 6.2rem;
    /* 120px */
    height: 6.2rem;
    /* 120px */
    padding-top: .9rem;
}

.footer-icon-insta {
    width: 4.5rem;
    /* 120px */
    height: 4.5rem;
    /* 120px */
}

.footer-text {
    font-size: 1rem;
    /* 16px */
    font-style: normal;
    /* Ensure text is not italic */
}