.min-h-screen {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.testimonials-container {
    display: flex;
    flex-direction: column;
    /* Stack cards vertically */
    align-items: center;
    /* Center cards horizontally */
    padding: 20px;
    gap: 20px;
    width: 100%;
}

/* #4b5a70 */
.testimonial-card {
    border-radius: 10px;
    padding: 20px;
    margin: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: white;
    background-color: #343a40;
    /* Added background color directly in CSS */
    max-width: 600px;
    /* Maximum width for larger screens */
    width: calc(100% - 40px);
    /* Ensure responsive width */
}