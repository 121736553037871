/* Dosage.css */

.dosage-container {
    background-color: #fef3c7;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 32px 0;
    /* Add more space on top and bottom */
    position: relative;
    /* Needed for absolutely positioning the button */
}

.dosage-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    /* Align items to the start to make text higher */
    width: 80%;
    margin: 0 auto;
}

.image-container4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* Align images to the top */
    margin-right: 2rem;
    /* Add space to the right of the images */
}

.dosage-img {
    margin-bottom: 1rem;
    /* Add space below the dosage image */
}

.gem-img {
    margin-top: 1rem;
    /* Add space above the gem image */
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* Align text to the top */
    font-family: 'Josefin Sans', sans-serif;
    line-height: 1.5;
    /* Add more space between lines */
    max-width: 50%;
    /* Limit the width of the text container */
    margin-left: auto;
    /* Center text more */
    position: relative;
    /* Needed for absolutely positioning the button */
}

.text-container h3 {
    font-weight: bold;
    /* Make h3 elements bold */
    margin-top: 1rem;
    /* Add space above h3 elements */
    margin-bottom: 0.5rem;
    /* Add space below h3 elements */
    color: black;
    text-shadow: 1px 1px 2px rgb(255, 255, 255);
}

.text-container ul,
.text-container p {
    list-style-type: disc;
    padding-left: 20px;
    color: black;
    /* Change text color to black */
    text-align: left;
    margin-top: 0;
    text-shadow: 1px 1px 2px rgb(255, 255, 255);
    /* Maintain shadow on hover */
}

.more-info-container {
    margin-top: 4rem;
    /* Add some space above the More Info section */
    text-align: center;
    /* Center the content */
    width: 100%;
    /* Make sure the container takes full width */
}

.education-button {
    display: inline-block;
    background-color: white;
    color: black;
    border: 1px solid white;
    padding: 0.5rem 1rem;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 0.5rem;
    transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 10rem;
}

.education-button:hover {
    background-color: #fef3c7;
    color: black;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
    /* Increase shadow on hover */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    /* Maintain shadow on hover */
}

@media (max-width: 938px) {
    .text-container {
        font-size: 0.875rem;
    }

    .text-container h3 {
        font-size: .875rem;
    }

    .education-button {
        font-size: 0.875rem;
        padding: 0.4rem;
        width: 8rem;

    }

    .more-info-container {
        margin-top: 2rem;
    }
}

@media (max-width: 832px) {
    .text-container {
        font-size: 0.775rem;
    }

    .text-container h3 {
        font-size: .775rem;
    }

    .education-button {
        font-size: 0.775rem;
        padding: 0.4rem;
    }

    .more-info-container {
        margin-top: 1.3rem;
    }
}

@media (max-width: 732px) {
    .text-container {
        font-size: 0.675rem;
    }

    .text-container h3 {
        font-size: .675rem;
    }

    .education-button {
        font-size: 0.675rem;
        padding: 0.4rem;
        width: 7rem;
    }

    .more-info-container {
        margin-top: 1.3rem;
    }
}

@media (max-width: 632px) {
    .text-container {
        font-size: 0.575rem;
    }

    .text-container h3 {
        font-size: .575rem;
    }

    .education-button {
        font-size: 0.575rem;
        padding: 0.3rem;
        width: 5rem;
    }

    .more-info-container {
        margin-top: 1rem;
    }
}

@media (max-width: 532px) {
    .text-container {
        font-size: 0.475rem;
        margin-left: -2.5rem;
    }

    .text-container h3 {
        font-size: .475rem;
    }

    .education-button {
        font-size: 0.475rem;
        padding: 0.2rem;
        width: 5rem;

    }

    .more-info-container {
        margin-top: .1rem;
    }
}

@media (max-width: 432px) {
    .text-container {
        font-size: 0.375rem;
    }

    .text-container h3 {
        font-size: .375rem;
    }

    .education-button {
        font-size: 0.375rem;
        padding: 0.2rem;
        width: 5rem;

    }

    .more-info-container {
        margin-top: .1rem;
    }
}