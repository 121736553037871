* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

#root {
    height: 100vh;
}

.map-container {
    display: flex;
}

.sidebar {
    width: 20%;
    height: 100vh;
    overflow-y: auto;
    padding: 10px;
    background-color: #f8f9fa;
    border-right: 1px solid #ddd;
}

.sidebar-header {
    padding: 15px;
    background-color: #ECFCCB;
    /* bg-lime-100 */
    color: black;
    text-align: center;
    font-size: 18px;
    margin-bottom: 10px;
    border-radius: 5px;
    text-shadow: 1px 1px 2px white;
}

.sidebar-item {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
}

.sidebar-item h2 {
    margin: 0 0 5px 0;
    font-size: 18px;
    cursor: pointer;
}

.sidebar-item h2:hover {
    color: green;
}

.sidebar-item p {
    margin: 0;
    font-size: 14px;
}

.sidebar-item a {
    color: black;
    text-decoration: none;
}

.sidebar-item a:hover {
    color: blue;
}

.leaflet-container {
    height: 100vh;
    width: 80%;
}

.custom-marker-cluster {
    background-color: rgba(0, 123, 255, 0.6);
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 12px;
    width: 33px;
    height: 33px;
}

.popup-container {
    text-align: center;
}

.popup-image {
    width: 100%;
    height: auto;
    max-height: 150px;
    margin-bottom: 10px;
}

.popup-container h2 {
    margin: 10px 0 5px 0;
}

.popup-container p {
    margin: 0;
}

.popup-container a {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.reset-view-button {
    position: absolute;
    top: 4.5rem;
    left: .6rem;
    z-index: 1000;
    background-color: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
}

.reset-view-button img {
    width: 2.2rem;
    /* Adjust size as needed */
    height: 2.2rem;
    /* Adjust size as needed */
}

.reset-view-button:hover img {
    opacity: 0.8;
}

@media (max-width: 550px) {
    .sidebar-item h2 {
        margin: 0 0 5px 0;
        font-size: 12px;
        cursor: pointer;
    }

    .sidebar-item p,
    a {
        margin: 0;
        font-size: 7px;
    }

    .sidebar-header {
        padding: 10px;
        background-color: #ECFCCB;
        /* bg-lime-100 */
        color: black;
        text-align: center;
        font-size: 12px;

    }
}