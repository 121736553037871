/* GemmyRoll.css */
.full-slide {
    display: flex;
    height: 100vh;
    width: 100vw;
    margin: -2rem;
    padding: 0;
    position: relative;
    overflow: hidden;
}

.background-half {
    height: 100%;
    width: 50%;
    position: absolute;
    top: 0;
}

.left-background {
    left: 0;
    background-color: #a7f3d0;
    /* Equivalent to bg-emerald-200 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    /* Add padding to the content */
}

.right-background {
    right: 0;
    background-color: #fef3c7;
    /* Equivalent to bg-yellow-200 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    /* Add padding to the content */
}

.center-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* Align items to the top */
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    margin-top: 6rem;
    /* Move the entire content down */
}

.right-image {
    max-width: 40%;
    margin-bottom: -8.5rem;
}

.right-header {
    font-size: 1.1rem;
    margin-bottom: 7rem;
    color: #333;
    width: 14rem;
    font-family: 'Josefin Sans', sans-serif;
    text-align: center;
}

.left-button {
    z-index: 100;
    padding: 1rem 2rem;
    margin-bottom: -2rem;
    margin-top: 7rem;
    /* Adjusted for spacing */
    background-color: #a7f3d0;
    /* Match the emerald background */
    border: 2px solid #fff;
    /* Add a white border */
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.2rem;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold;
    /* Corrected to font-weight */
    color: white;
    text-shadow: 2px 2px 4px black;
    /* Correct text-shadow property */
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    cursor: hand;
    /* Add shadow to the border */
}

.syringe-image {
    max-width: 32%;
    max-height: 32%;
    position: absolute;
    top: -0.7rem;
    /* Adjust to move the syringe behind the gemFrGrn */
    z-index: 1;
}

.center-image {
    max-width: 80%;
    max-height: 80%;
    margin-top: 6rem;
    /* Adjust to move the gemFrGrn image down */
    z-index: 2;
}

.right-button {
    padding: 1rem 2rem;
    margin-bottom: -2rem;
    margin-top: 7rem;
    margin-right: 2rem;
    background-color: #faa957;
    /* Match the yellow background */
    border: 2px solid #fff;
    /* Add a white border */
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.2rem;
    font-family: 'Josefin Sans', sans-serif;
    font-style: bold;
    text-shadow: black;
    color: white;
    text-shadow: 2px 2px 4px black;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.left-image {
    max-width: 40%;
    margin-bottom: -8.5rem;
}

.left-header {
    font-size: 1.1rem;
    /* margin-top: 4rem; */
    margin-bottom: 7rem;
    color: #333;
    width: 14rem;
    font-style: bold;
    font-family: 'Josefin Sans', sans-serif;
    text-align: center;
}

.left-background2 {
    left: 0;
    background-color: #fef3c7;
    /* Equivalent to bg-emerald-200 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    /* Add padding to the content */
}

.right-background2 {
    right: 0;
    background-color: #faa957;
    /* Equivalent to bg-yellow-200 */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    /* Add padding to the content */
}

.swiper-button-next,
.swiper-button-prev {
    width: 30px;
    height: 30px;
    color: black;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 20px;
}

@media (max-width: 841px) {
    .syringe-image {
        max-width: 18%;
    }

    .center-image {
        max-width: 60%;
        max-height: 60%;
    }


    /* .right-header {} */

    .left-header,
    .right-header {
        font-size: .8rem;
        margin-bottom: 13rem;
        width: 10rem;
    }

    .left-button,
    .right-button {
        padding: 0.8rem 1.5rem;
        font-size: .8rem;
        margin-bottom: 2rem;
        margin-top: 1rem;
    }

    .center-content {
        margin-top: 4rem;
    }
}

@media (max-width: 592px) {

    .syringe-image {
        max-width: 15%;
        margin-top: 4rem;
    }

    .center-image {
        max-width: 40%;
        max-height: 40%;
        margin-top: 10rem;
    }

    .left-header,
    .right-header {
        font-size: .6rem;
        margin-bottom: 13rem;
        width: 9rem;
    }

    .left-button,
    .right-button {
        padding: 0.6rem 1.1rem;
        font-size: .6rem;
        margin-bottom: 1rem;
        margin-top: 2rem;
    }

    .left-image,
    .right-image {
        margin-top: 3rem;
    }
}

@media (max-width: 451px) {
    .syringe-image {
        max-width: 18%;
    }

    .center-image {
        max-width: 60%;
        max-height: 60%;
    }


    /* .right-header {} */

    .left-header,
    .right-header {
        font-size: .8rem;
        margin-bottom: 13rem;
        width: 10rem;
    }

    .left-button,
    .right-button {
        padding: 0.8rem 1.5rem;
        font-size: .8rem;
        margin-bottom: 2rem;
        margin-top: 1rem;
    }

    .center-content {
        margin-top: 4rem;
    }
}

@media (max-width: 592px) {

    .syringe-image {
        max-width: 15%;
        margin-top: 4.5rem;
    }

    .center-image {
        max-width: 35%;
        max-height: 35%;
        margin-top: 10rem;
    }

    .left-header,
    .right-header {
        font-size: .6rem;
        margin-bottom: 14.3rem;
        width: 9rem;
    }

    .left-button,
    .right-button {
        padding: 0.4rem .9rem;
        font-size: .5rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .left-image,
    .right-image {
        margin-top: 4rem;
    }
}