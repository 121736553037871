.mySwiper {
    height: 100%;
    background-color: transparent;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 2rem 1rem;
    background-color: transparent;
}

.swiper-slide .img-container {
    width: 90%;
    /* Adjust width to make the images smaller */
    height: 90%;
    /* Adjust height accordingly */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
}

.swiper-slide img {
    width: 100%;
    height: 100%;
    /* Set the height to match the container's height */
    object-fit: contain;
}