/* ImageMagnifier.css */

.magnifier-container {
    position: relative;
}

.magnifier-img {
    display: block;
    width: 100%;
    height: auto;
}

.magnifier {
    display: none;
    position: absolute;
    width: 200px;
    /* Set width to 200px */
    height: 100px;
    /* Set height to 100px */
    border: 1px solid lightgray;
    cursor: none;
    pointer-events: none;
    /* Prevent magnifier from blocking mouse events */
    background-repeat: no-repeat;
    background-color: white;
    opacity: 1;
}

.magnifier-container:hover .magnifier {
    display: block;
}